import React, { useEffect, useRef, useState } from 'react'

import update from 'react-addons-update'
import { useNavigate } from 'react-router'
import styled, { useTheme } from 'styled-components'

import { GlobalModalTypeEnum, ModalPlugin } from '@api/local/ModalPlugin'
import { Paragraph, Title, Card, FavouriteButton, Button, LocalIconEnums, Link } from '@atoms/index'
import { ResponsivePXValue } from '@components/Theme'
import { ProductDetailsFragment, useAddProductToCartMutation, useChangeCartItemQuantityMutation, useCustomerQuery, useProductAlertSubscribeMutation, useRemoveItemFromCartMutation } from '@hooks/api/index'
import { useCartId } from '@hooks/UseCartId'
import { SiteHelper } from '@lib/SiteHelper'
import { useSimpleToasts } from '@simple/toasts'
import { CustomerTypeEnum, ProductStockStatus } from '@uctypes/api/globalTypes'
import { DeviceContainer } from '@utility/DeviceContainer'

import { AddToCartButton, AddToCartDisplayTypeEnum } from './AddToCartButton'

const QUANTITY_CHANGE_TIMEOUT = 2000

const Container = styled.div`
  width: 100%;
  ${ResponsivePXValue('box-shadow', '0px -2px 6px rgba(0, 0, 0, 0.1)')}
  .card {
    margin: 0;
    ${ResponsivePXValue('padding', '10px 12px 12px 12px')}
  }
  .add-to-cart-button {
    width: 100%;
  }
`

const UpperContainer = styled.div`
  .heading {
    ${ResponsivePXValue('margin', '0 5px 0 0')}
  }
  .notify {
    ${ResponsivePXValue('margin', '0 0 12px 0')}
  }
  .mobile-inner {
    margin: 0;
  }
`

const InnerContainer = styled.div`
  display: flex;
  flex-direction: 'row';
  align-items: center;
  justify-content: space-between;
  ${ResponsivePXValue('margin', '0 0 10px 0')}
  .discount {}
`

const PriceContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const CartFavContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${ResponsivePXValue('gap', '12px')}
`

export enum ProductAlertTypeEnum {
  PRODUCT_ALERT_IN_STOCK = 'PRODUCT_ALERT_IN_STOCK',
  PRODUCT_ALERT_PRICE = 'PRODUCT_ALERT_PRICE',
}

export interface AddSimpleProductToCartProps {
  product: ProductDetailsFragment
  loading: boolean
}

interface AddSimpleProductToCartState {
  price: string
  originalPrice: string
  newAmount: number | null
}

const DEFAULT_STATE: AddSimpleProductToCartState = {
  price: '',
  originalPrice: '',
  newAmount: null,
}

export function AddSimpleProductToCart({ product, loading }: AddSimpleProductToCartProps): JSX.Element {

  const theme = useTheme()
  const navigate = useNavigate()
  const { data: customerData } = useCustomerQuery()
  const [state, setState] = useState<AddSimpleProductToCartState>({ ...DEFAULT_STATE })
  const { cartId } = useCartId()
  const { addToast } = useSimpleToasts()
  const updateTimerRef = useRef(null)
  const [addProductToCart, { loading: addLoading }] = useAddProductToCartMutation()
  const [removeItemFromCart, { loading: removeLoading }] = useRemoveItemFromCartMutation()
  const [changeQuantity, { loading: quantityLoading }] = useChangeCartItemQuantityMutation()
  const [productAlertSubscribeMutation, { loading: ProductAlertSubscriptionLoading }] = useProductAlertSubscribeMutation()

  const addProduct = async (): Promise<void> => {
    try {
      const response = await addProductToCart({
        variables: {
          cartId,
          sku: product.sku,
          quantity: 1,
        },
      })
      if (response?.data?.addProduct?.userErrors?.length) {
        response?.data?.addProduct?.userErrors?.forEach?.(({ message }) => addToast({
          message,
          appearance: 'error',
        }))
      }
    } catch (e) {
      addToast({
        message: e.message,
        appearance: 'error',
      })
    }
  }

  const removeProduct = async (): Promise<void> => {
    try {
      await removeItemFromCart({
        variables: {
          cartId,
          cartItemUid: product.cartItemUid,
        },
      })
    } catch (e) {
      addToast({
        message: e.message,
        appearance: 'error',
      })
    } finally {
      setState((prevState) => update(prevState, {
        newAmount: { $set: null },
      }))
    }
  }

  const changeProduct = async (): Promise<void> => {
    updateTimerRef.current = setTimeout(async () => {
      try {
        await changeQuantity({
          variables: {
            cartId,
            cartItemUid: product.cartItemUid,
            quantity: state.newAmount,
          },
        })
      } catch (e) {
        addToast({
          message: e.message,
          appearance: 'error',
        })
      } finally {
        setState((prevState) => update(prevState, {
          newAmount: { $set: null },
        }))
      }
    }, QUANTITY_CHANGE_TIMEOUT)
  }

  const updateProduct = async (): Promise<void> => {
    if (updateTimerRef.current) {
      clearTimeout(updateTimerRef.current)
    }
    if (!product.quantityInCart) {
      await addProduct()
    } else if (product.quantityInCart && state.newAmount === 0) {
      removeProduct()
    } else {
      changeProduct()
    }
  }

  const _handleAddProduct = async (): Promise<void> => {
    await addProduct()
  }

  const _handleIncreaseProduct = async (): Promise<void> => {
    const newAmount = state.newAmount === null ? product?.quantityInCart + 1 : state.newAmount + 1
    setState((prevState) => update(prevState, {
      newAmount: { $set: newAmount },
    }))
  }

  const _handleDecreaseProduct = async (): Promise<void> => {
    let newAmount = state.newAmount === null ? product?.quantityInCart - 1 : state.newAmount - 1
    if (newAmount < 0) {
      newAmount = 0
    }
    setState((prevState) => update(prevState, {
      newAmount: { $set: newAmount },
    }))
  }

  const _handleProductAlertSubscription = async (): Promise<void> => {
    if (customerData.currentCustomer.customerType === CustomerTypeEnum.GUEST) {
      ModalPlugin.shared().toggleGlobalModal(true, GlobalModalTypeEnum.LOG_IN)
    } else {
      try {
        await productAlertSubscribeMutation({
          variables: {
            productId: product.id as unknown as string,
            type: ProductAlertTypeEnum.PRODUCT_ALERT_IN_STOCK as unknown as string,
          },
        })
      } catch (e) {
        addToast({
          message: e.message,
          appearance: 'error',
        })
      } finally {
        addToast({
          message: 'Alert subscription has been saved',
          appearance: 'success',
        })
      }

    }
  }

  const displayType = product?.stockInfo?.display === "Out of stock"
    ? AddToCartDisplayTypeEnum.OUT_OF_STOCK
    : product?.quantityInCart
      ? AddToCartDisplayTypeEnum.QUANTITY
      : AddToCartDisplayTypeEnum.ADD

  useEffect(() => {
    if (product?.priceRange) {
      setState((prevState) => update(prevState, {
        price: { $set: SiteHelper.formatCurrency(product.priceRange?.minimumPrice?.finalPrice) },
      }))
      if (product.priceRange?.minimumPrice?.finalPrice?.value !== product.priceRange?.minimumPrice?.regularPrice?.value) {
        setState((prevState) => update(prevState, {
          originalPrice: { $set: SiteHelper.formatCurrency(product.priceRange?.minimumPrice?.regularPrice) },
        }))
      }
    }
  }, [product?.priceRange?.minimumPrice])

  useEffect(() => {
    if (state.newAmount !== null) {
      updateProduct()
    }
  }, [state.newAmount])

  const _handleGoToCart = (e: React.MouseEvent<HTMLAnchorElement>): void => {
    e.preventDefault()
    navigate('/cart')
  }

  const goToCartBtnColors = {
    color: theme.colors.green.bottleGreen,
    backgroundColor: theme.colors.misc.transparent,
    borderColor: theme.colors.grey.gallery,
    hoverColor: theme.colors.white.pureWhite,
    hoverBackgroundColor: theme.colors.green.bottleGreen,
    hoverBorderColor: theme.colors.green.bottleGreen,
    disabledColor: SiteHelper.getOpaqueColor(theme.colors.green.bottleGreen, 0.45),
    disabledBackgrondColor: theme.colors.misc.transparent,
    disabledBorderColor: SiteHelper.getOpaqueColor(theme.colors.green.bottleGreen, 0.45),
  }

  const actualLoading = loading || addLoading || quantityLoading || removeLoading

  return (
    <Container>
      <Card className='card' >
        <DeviceContainer desktop>
          <UpperContainer>
            <>
              <InnerContainer>
                <PriceContainer>
                  <Title className='heading' variant='t9' >{state.price}</Title>
                  <If condition={state.originalPrice !== ''}>
                    <Paragraph
                      color={theme.colors.grey.gunSmoke}
                      variant='p2'
                      className='discount'
                      decoration='line-through'>
                      {state.originalPrice}
                    </Paragraph>
                  </If>
                </PriceContainer>
                <CartFavContainer>
                  <FavouriteButton className='favourite' product={product} />
                  <If condition={product?.quantityInCart > 0}>
                    <DeviceContainer mobile>
                      <Button
                        title='GO TO'
                        rightIcon={LocalIconEnums.CART_FILLED}
                        shape='round' size='large'
                        colors={goToCartBtnColors}
                        href='/cart'
                        onClick={_handleGoToCart} />
                    </DeviceContainer>
                  </If>
                </CartFavContainer>
              </InnerContainer>
              <If condition={displayType === AddToCartDisplayTypeEnum.OUT_OF_STOCK}>
                <Paragraph>
                  <Link className='notify' href='#' display='block' onClick={_handleProductAlertSubscription}>Notify me when back in stock</Link>
                </Paragraph>
              </If>
            </>
          </UpperContainer>
          <AddToCartButton
            displayType={displayType}
            currentQuantity={state.newAmount ? state.newAmount : product.quantityInCart}
            loading={actualLoading}
            onAdd={_handleAddProduct}
            onIncrease={_handleIncreaseProduct}
            onDecrease={_handleDecreaseProduct} />
        </DeviceContainer>
        <DeviceContainer mobile tablet>
          <UpperContainer>
            <>
              <InnerContainer className='mobile-inner'>
                <PriceContainer>
                  <Title className='heading' variant='t9' >{state.price}</Title>
                  <If condition={state.originalPrice !== ''}>
                    <Paragraph
                      color={theme.colors.grey.gunSmoke}
                      variant='p2'
                      className='discount'
                      decoration='line-through'>
                      {state.originalPrice}
                    </Paragraph>
                  </If>
                </PriceContainer>
                <CartFavContainer>
                  <AddToCartButton
                    displayType={displayType}
                    currentQuantity={state.newAmount ? state.newAmount : product.quantityInCart}
                    loading={actualLoading}
                    onAdd={_handleAddProduct}
                    onIncrease={_handleIncreaseProduct}
                    onDecrease={_handleDecreaseProduct} />
                </CartFavContainer>
              </InnerContainer>
              <If condition={displayType === AddToCartDisplayTypeEnum.OUT_OF_STOCK}>
                <Paragraph>
                  <Link className='notify' href='#' display='block' onClick={_handleProductAlertSubscription}>Notify me when back in stock</Link>
                </Paragraph>
              </If>
            </>
          </UpperContainer>
        </DeviceContainer>
      </Card>
    </Container>
  )
}
