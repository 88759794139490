import React, { ChangeEvent, useRef } from 'react'

import { Field, FormInstance } from 'rc-field-form'
import { Meta, Rule } from 'rc-field-form/es/interface.d'
import styled, { css, CSS, useTheme } from 'styled-components'

import { SmallLoader } from '@atoms/index'
import { LiteBoxShadow, ResponsivePXValue } from '@components/Theme'
import { InputWrapper, FormContext, FormContextProperties } from '@molecules/inputs/index'

const Container = styled.div`
  position: relative;
 `

export const TextAreaInputElement = styled.textarea<{ error: boolean, disabled: boolean, boldUserInputText: boolean }>`
  font-family: open-sans;
  border-style: solid;
  color: ${(props): string => props.error ? props.theme.colors.red.cinnabar : props.theme.colors.green.bottleGreen};
  border-color: ${(props): string => props.error ? props.theme.colors.red.cinnabar : props.theme.colors.grey.silver};
  background-color: ${(props): string => props.theme.colors.white.pureWhite};
  width: 100%;
  box-shadow: none;

  ${(props) => props.boldUserInputText
    ? ResponsivePXValue('font-weight', '700') 
    : ResponsivePXValue('font-weight', { mobile: '100', tablet: '400', desktop: '400' })
  }

  ${ResponsivePXValue('font-size', { mobile: '10px', tablet: '12px', desktop: '12px' })};
  ${ResponsivePXValue('height', '136px')};
  ${ResponsivePXValue('padding', { mobile: '5px', tablet: '5px', desktop: '13px' })};

  ${ResponsivePXValue('border-width', '1px')}
  ${ResponsivePXValue('height', '136px')}


  &:focus {
    outline: none;
    box-shadow: none;
    border-color: ${(props): string => props.error ? props.theme.colors.red.cinnabar : props.theme.colors.grey.silver};
    ${LiteBoxShadow}
  }

  ::placeholder {
    color: ${(props): string => props.theme.colors.grey.silver};
    opacity: 1;
    ${ResponsivePXValue('font-size', { mobile: '10px', tablet: '12px', desktop: '12px' })};
    font-weight: initial;

  }

  ${(props): CSS => {
    if (props.disabled) {
      return css`
        border-color: ${(props): string => props.theme.colors.white.romance};
        color: ${(props): string => props.theme.colors.white.romance};
        background-color: ${(props): string => props.theme.colors.grey.gallery};
        ::placeholder {
          color: ${(props): string => props.theme.colors.white.romance};
          opacity: 1;
        }
      `
    }
  }}
`

const LoadingContainer = styled.div`
  position: absolute;
  ${ResponsivePXValue('right', '12px')}
  ${ResponsivePXValue('top', '12px')}
  ${ResponsivePXValue('width', '20px')}
  ${ResponsivePXValue('height', '20px')}
`

interface Values {
  value?: string | number
  onChange?: (value: string | number) => void
}

export interface TextAreaFormInputProps {
  rules?: Rule[]
  label?: string
  showLabel?: boolean
  name: string
  loading?: boolean
  disabled?: boolean
  placeholder?: string
  className?: string
  wrapperClassName?: string
  boldUserInputText: boolean
}

export function TextAreaInput(props: TextAreaFormInputProps): JSX.Element {

  const { placeholder = '', boldUserInputText, label, rules, showLabel, name, loading, disabled, className, wrapperClassName } = props
  const inputRef: React.RefObject<HTMLTextAreaElement> = useRef()
  const theme = useTheme()

  return (
    <Field name={name} rules={rules}>
      {(control: Values, meta: Meta, form: FormInstance) => {
        const required = !!(
          rules &&
          rules.some(rule => {
            if (rule && typeof rule === 'object' && rule.required) {
              return true
            }
            if (typeof rule === 'function') {
              const ruleEntity = rule(form)
              return ruleEntity && ruleEntity.required
            }
            return false
          })
        )
        const error = meta.errors?.[0]
        const { value, onChange } = control

        const _handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
          const newValue = e.target.value
          onChange(newValue)
        }

        return (
          <InputWrapper
            required={required}
            label={label}
            showLabel={showLabel}
            error={error}
            className={wrapperClassName}>
            <FormContext.Consumer>
              {({ loading: formLoading, disabled: formDisabled, registerRef }: FormContextProperties) => {
                registerRef?.(name, inputRef)
                return (
                  <Container className={className}>
                    <TextAreaInputElement
                      autoComplete='on'
                      disabled={loading || disabled || formLoading || formDisabled}
                      name={name}
                      error={!!error}
                      value={value ? value + '' : ''}
                      placeholder={placeholder}
                      onChange={_handleChange} 
                      boldUserInputText={boldUserInputText}/>
                    <If condition={loading || formLoading}>
                      <LoadingContainer>
                        <SmallLoader
                          color={theme.colors.green.goldenrod} />
                      </LoadingContainer>
                    </If>
                  </Container>
                )
              }}
            </FormContext.Consumer>
          </InputWrapper>
        )
      }}
    </Field>
  )

}
