import React from 'react'

import styled from 'styled-components'

import { APP_DEFAULT_STATE } from '@api/local/AppPlugin'
import { Button } from '@atoms/buttons'
import { LocalIconEnums } from '@atoms/images/Icons'
import { ResponsivePXValue } from '@components/Theme'
import { useGetAppQuery } from '@hooks/api'
import { DeviceTypeEnum } from '@uctypes/api/globalTypes'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${ResponsivePXValue('gap', '6px')}
  ${ResponsivePXValue('margin-bottom', '16px')}
  ${ResponsivePXValue('width', { mobile: '100%' })}
`

const ButtonContainer = styled.div`
  display: flex;
  ${ResponsivePXValue('width', { mobile: '100%' })}
  ${ResponsivePXValue('padding', { mobile: '0 8px' })}
  ${ResponsivePXValue('justify-content', { mobile: 'space-between', tablet: 'center', desktop: 'center' })}
  align-items: center;
  ${ResponsivePXValue('gap', { tablet: '6px', desktop: '6px' })}
`

export interface PaginationProps {
  currentPage: number
  itemsPerPage: number
  totalItems: number
  loading: boolean
  itemsToShow?: number
  onPageChange: (page: number) => void
}

export function Pagination({ currentPage, itemsPerPage, totalItems, loading, itemsToShow, onPageChange }: PaginationProps): JSX.Element {

  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()
  if (!itemsToShow) {
    itemsToShow = appData.app.deviceType === DeviceTypeEnum.MOBILE ? 6 : 10
  }

  let pageCount = Math.round(totalItems / itemsPerPage)
  if (totalItems % itemsPerPage) {
    pageCount++
  }

  const hasMore = currentPage < pageCount
  const hasLess = currentPage > 1
  let startPage = Math.round(currentPage - (itemsToShow / 2))
  if (startPage < 1) {
    startPage = 1
  }
  let endPage = startPage + itemsToShow
  if (endPage > pageCount) {
    endPage = pageCount
    startPage = endPage - itemsToShow
  }
  if (startPage < 1) {
    startPage = 1
  }

  console.log(`PAGE COUNT: ${pageCount} HAS MORE: ${hasMore} HAS LESS: ${hasLess} START PAGE: ${startPage} END PAGE: ${endPage}`)

  const $buttons: JSX.Element[] = []
  for (let b = startPage; b <= endPage; b++) {
    $buttons.push(
      <Button
        size={appData.app.deviceType === DeviceTypeEnum.MOBILE ? 'small' : undefined}
        title={`${b}`} onClick={() => onPageChange(b)}
        variant={currentPage === b ? 'direction' : 'nav'}
        disabled={currentPage === b} />,
    )
  }

  return (
    <Container>
      <ButtonContainer>
        <Button
          size={appData.app.deviceType === DeviceTypeEnum.MOBILE ? 'small' : undefined}
          icon={LocalIconEnums.CHEVRON_LEFT}
          onClick={() => onPageChange(currentPage - 1)}
          disabled={!hasLess} />
        {$buttons}
        <Button
          size={appData.app.deviceType === DeviceTypeEnum.MOBILE ? 'small' : undefined}
          icon={LocalIconEnums.CHEVRON_RIGHT}
          onClick={() => onPageChange(currentPage + 1)}
          disabled={!hasMore} />
      </ButtonContainer>
      Page {currentPage} of {pageCount}
    </Container>
  )

}
