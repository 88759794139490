import React from 'react'

import { useNavigate } from 'react-router'
import styled from 'styled-components'

import { Button, Paragraph, ResponsiveImage } from '@atoms/index'
import { ResponsivePXValue } from '@components/Theme'
import { SpecialsChildCategoryFragment } from '@hooks/api'
import { FlexDeviceContainer } from '@utility/DeviceContainer'

const Container = styled.a`
  display: flex;
  flex-direction: column;
  ${ResponsivePXValue('width', { mobile: '154px', tablet: '290px', desktop: '290px' })}
  ${ResponsivePXValue('height', '277px')}
  background-color: ${(props): string => props.theme.colors.white.pureWhite};
  text-decoration: none;
`

const ImageContainer = styled.div`
  width: 100%;
  flex-shrink: 0;
  overflow: hidden;
  img {
    outline: 2px solid white;
    outline-offset: -2px;
  }
  picture {
    outline: 2px solid white;
    outline-offset: -2px;
  }
`

const ContentContainer = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  ${ResponsivePXValue('padding', { mobile: '8px', tablet: '16px', desktop: '16px' })}

  .title {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    word-wrap: break-word;
    white-space: normal;
  }
  
`

const ButtonContainer = styled.div`
  width: 100%;
  ${ResponsivePXValue('padding', { mobile: '0px', tablet: '0 45px', desktop: '0 45px' })}
`

export interface SpecialCardProps {
  category: SpecialsChildCategoryFragment
}

export function SpecialCard({ category }: SpecialCardProps): JSX.Element {

  const navigate = useNavigate()

  const _handleClick = (e: React.MouseEvent<HTMLAnchorElement>): void => {
    e.preventDefault()
    navigate(category.canonicalUrl)
  }

  return (
    <Container href={category.canonicalUrl} onClick={_handleClick}>
      <ImageContainer>
        <ResponsiveImage image={category.coverImage} objectFit='contain' />
      </ImageContainer>
      <ContentContainer>
        <FlexDeviceContainer mobile>
          <Paragraph bold align='center' className='title' variant='p3'>{category.name}</Paragraph>
        </FlexDeviceContainer>
        <FlexDeviceContainer tablet desktop>
          <Paragraph bold align='center' className='title'>{category.name}</Paragraph>
        </FlexDeviceContainer>
        <ButtonContainer>
          <Button title='SHOP DEAL' href={category.canonicalUrl} variant='primary' size='medium' fullWidth/>
        </ButtonContainer>
      </ContentContainer>
    </Container>
  )

}
