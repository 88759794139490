import React, { useEffect, useState } from 'react'

import { ApolloClient, NormalizedCacheObject, useApolloClient } from '@apollo/client'

import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import update from 'react-addons-update'
import { useNavigate } from 'react-router'
import styled, { useTheme } from 'styled-components'
import { v4 } from 'uuid'

import { APP_DEFAULT_STATE } from '@api/local/AppPlugin'
import { AuthPlugin } from '@api/local/AuthPlugin'
import { ModalPlugin, GlobalModalTypeEnum } from '@api/local/ModalPlugin'
import { NAVIGATION_DEFAULT_STATE, NavigationPlugin } from '@api/local/NavigationPlugin'
import { Link, Button, LocalIconEnums, Paragraph, Title, SmallLoader } from '@atoms/index'
import { navigation, NavItem, NavItemTypeEnum } from '@client/Navigation'
import { ResponsivePXValue } from '@components/Theme'
import { useCustomerQuery, useGetNavigationQuery, useGetAppQuery, useGetCategoryMenuQueryLazyQuery, useGetFrequentlyBoughtProductsQuery, usePersonalDiscountsQuery } from '@hooks/api/index'
import { SiteHelper } from '@lib/SiteHelper'
import { CustomerTypeEnum, DeviceTypeEnum } from '@uctypes/api/globalTypes'

enum ListPosition {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
  CENTER = 'CENTER',
}

const SidebarContainer = styled.div<{ open: boolean }>`
  width: 100%;
  position: fixed;
  overflow: scroll;
  scroll-padding-bottom: 0;
  top: 0;
  z-index: 40;
  left: ${(props) => props.open ? '0' : '-100%'};
  transition: all ease-out 0.3s;
  ${ResponsivePXValue('height', 'CALC(100% + 15px)')}
`

const Container = styled.div`
  position: relative;
  height: 100%;
  background-color: ${(props): string => props.theme.colors.white.pureWhite};
  ${ResponsivePXValue('width', 'CALC(100% - 50px)')}
  overflow-x: hidden;
  .start-left {
    left: 100%;
  }
  .start-right {
    left: -100%;
  }
  .start-center {
    left: 0;
  }
  .animate-left {
    transition: all ease-out 0.3s;
    left: 100%;
  }
  .animate-center {
    transition: all ease-out 0.3s;
    left: 0;
  }
  .animate-right {
    transition: all ease-out 0.3s;
    left: -100%;
  }
`

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${ResponsivePXValue('padding', '8px')}
  ${ResponsivePXValue('height', '48px')}
  ${(props): string => ResponsivePXValue('border-bottom', `2px solid ${props.theme.colors.grey.desertStorm}`)}
`

const AnimatedContainer = styled.div`
  ${ResponsivePXValue('height', 'CALC(100% - 110px)')}
  position: relative;
  display: flex;
  flex-direction: column;
`

const ItemsContainer = styled.div`
  flex-grow: 1;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll; 
  overflow-x: hidden;
  &::-webkit-scrollbar {
      display: none; /* for Chrome, Safari, and Opera */
  }
`

const BottomContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${(props): string => props.theme.colors.white.fantasy};
  ${ResponsivePXValue('height', '48px')}
  .user-name {
    ${ResponsivePXValue('margin-left', '16px')}
  }
  .log-out {
    ${ResponsivePXValue('margin-right', '16px')}
  }
`

const NavigationItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${ResponsivePXValue('height', '42px')}
  ${ResponsivePXValue('padding-left', '8px')}
  ${(props): string => ResponsivePXValue('border-top', `1px solid ${props.theme.colors.grey.desertStorm}`)}
`

const GiftVoucherItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  ${ResponsivePXValue('gap', '8px')}
  ${ResponsivePXValue('height', '42px')}
  ${ResponsivePXValue('padding-left', '8px')}
  ${(props): string => ResponsivePXValue('border-top', `1px solid ${props.theme.colors.grey.desertStorm}`)}
  background-color: ${(props): string => props.theme.colors.green.swansDown};
  .gift-icon {
    ${ResponsivePXValue('width', '16px')}
    ${ResponsivePXValue('height', '16px')}
  }
`

const LoaderContainer = styled.div`
  ${ResponsivePXValue('width', '24px')}
  ${ResponsivePXValue('height', '24px')}
  ${ResponsivePXValue('margin-right', '8px')}
`

const NavigationBackItem = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  ${ResponsivePXValue('height', '42px')}
  ${ResponsivePXValue('padding-left', '8px')}
  ${(props): string => ResponsivePXValue('border-top', `1px solid ${props.theme.colors.grey.desertStorm}`)}

  .back-button {
    position: relative;
    ${ResponsivePXValue('left', '-10px')}
  }
  .back-title {
    position: relative;
    ${ResponsivePXValue('left', '-12px')}
  }
  .back-shop {
    ${ResponsivePXValue('margin-right', '8px')}
  }
`

const NavigationBackText = styled.div`
  flex-grow: 1;
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

export interface MobileNavigationMenuProps {
  onSelect: (path: string) => void
}

interface MenuContent {
  title: string
  path: string
  items: NavItem[]
}
interface MobileNavigationMenuState {
  mainMenu: NavItem[]
  levels: string[]
  currentMenu: MenuContent
  incomingMenu:MenuContent | null
  incommingDirection: ListPosition
  signOutLoading: boolean
  clickedMenuItem: string | null
  loadingMenuItem: string | null
}

const DEFAULT_STATE: MobileNavigationMenuState = {
  mainMenu: navigation,
  levels: [],
  currentMenu: {
    title: '',
    path: '',
    items: [],
  },
  incomingMenu: null,
  incommingDirection: ListPosition.CENTER,
  signOutLoading: false,
  clickedMenuItem: null,
  loadingMenuItem: null,
}

export function MobileNavigationMenu({ onSelect }: MobileNavigationMenuProps): JSX.Element {

  const [state, setState] = useState<MobileNavigationMenuState>({ ...DEFAULT_STATE })
  const { data: customerData, refetch: refetchCustomer } = useCustomerQuery()
  // const { refetch: refetchDiscounts } = usePersonalDiscountsQuery()
  const { refetch: refetchMyShop } = useGetFrequentlyBoughtProductsQuery()
  const { refetch: refetchDiscounts } = usePersonalDiscountsQuery()
  const { data: navigationData = { navigation: { ...NAVIGATION_DEFAULT_STATE } } } = useGetNavigationQuery()
  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()
  const [fetchCategories, { data: menuData }] = useGetCategoryMenuQueryLazyQuery()
  const apolloClient: ApolloClient<NormalizedCacheObject> = useApolloClient() as ApolloClient<NormalizedCacheObject>

  const theme = useTheme()
  const navigate = useNavigate()
  const { MOBILE } = DeviceTypeEnum

  const _handleItemClicked = (nav: NavItem): void => {
    if (nav.sub.length > 0) {
      setState((prevState) => update(prevState, {
        incomingMenu: {
          $set: {
            items: nav.sub,
            title: nav.title,
            path: nav.path,
          },
        },
        incomingDirection: {
          $set: ListPosition.RIGHT,
        },
      }))
    } else if (nav.path) {
      onSelect?.(nav.path)
    }
  }

  const _handleShopAllClicked = (path: string): void => {
    onSelect?.(path)
  }

  const _handleBackClick = (): void => {

  }

  const _handleToggleMenu = () => {
    NavigationPlugin.shared().toggleNavigation()
  }

  const _handleLoginModal = (e: React.MouseEvent<HTMLDivElement>): void => {
    setState((prevState) => update(prevState, {
      clickedMenuItem: {
        $set: (e.target as HTMLDivElement).innerText,
      },
    }))

    ModalPlugin.shared().toggleGlobalModal(true, GlobalModalTypeEnum.LOG_IN)
    NavigationPlugin.shared().closeNavigation()
  }

  const _handleSignUpModal = (): void => {
    ModalPlugin.shared().toggleGlobalModal(true, GlobalModalTypeEnum.SIGN_UP)
    NavigationPlugin.shared().closeNavigation()
  }

  const _handleSignOut = async (): Promise<void> => {
    setState((prevState) => update(prevState, {
      signOutLoading: { $set: true },
    }))

    AuthPlugin.shared().clear()
    await refetchCustomer()
    apolloClient.cache.evict({
      fieldName: 'personalDiscounts',
    })
    apolloClient.cache.evict({
      fieldName: 'frequentlyBoughtProducts',
    })
    refetchDiscounts()
    refetchMyShop()

    NavigationPlugin.shared().closeNavigation()
    setState((prevState) => update(prevState, {
      signOutLoading: { $set: false },
    }))
    navigate('/')
  }

  const _handleBackgroundClose = (e: React.MouseEvent<HTMLDivElement>): void => {
    e.preventDefault()

    if (e.target === e.currentTarget) {
      NavigationPlugin.shared().toggleNavigation()
    }
  }

  useEffect(() => {
    if (menuData?.categories && !state.mainMenu?.[1]?.sub?.length) {
      const newArr = [...state.mainMenu]
      const categories = menuData.categories.items.filter((item) => item.includeInMenu) || []
      for (let i = 0; i < categories.length; i++) {
        const subs: NavItem[] = []
        const subCategories = categories[i].children.filter((item) => item.includeInMenu) || []
        for (let ii = 0; ii < subCategories.length; ii++) {
          const subSubs: NavItem[] = []
          const subSubCategories = subCategories[ii].children.filter((item) => item.includeInMenu) || []
          for (let iii = 0; iii < subSubCategories.length; iii++) {
            subSubs.push({
              id: subSubCategories[iii].uid,
              title: subSubCategories[iii].name,
              path: `/${subSubCategories[iii].canonicalUrl}`,
              type: NavItemTypeEnum.CATEGORY,
              sub: [],
            })
          }
          subs.push({
            id: subCategories[ii].uid,
            title: subCategories[ii].name,
            path: `/${subCategories[ii].canonicalUrl}`,
            type: NavItemTypeEnum.CATEGORY,
            sub: subSubs,
          })
        }
        newArr[1].sub.push({
          id: categories[i].uid,
          title: categories[i].name,
          path: `/${categories[i].canonicalUrl}`,
          type: NavItemTypeEnum.CATEGORY,
          sub: subs,
        })
      }

      setState((prevState) => update(prevState, {
        mainMenu: { $set: newArr },
        currentMenu: {
          $set: {
            title: '',
            path: '',
            items: newArr,
          },
        },
      }))
    }
  }, [menuData])

  useEffect(() => {
    NavigationPlugin.shared().preventScroll(navigationData.navigation.navigationOpen)
    if (!navigationData.navigation.navigationOpen) {
      document && enableBodyScroll(document)
      setState((prevState) => update(prevState, {
        levels: {
          $set: [],
        },
        currentMenu: {
          $set: {
            title: '',
            path: '',
            items: state.mainMenu,
          },
        },
      }))
    } else {
      document && disableBodyScroll(document as unknown as Document, {
        allowTouchMove: (el) => {
          while (el && el !== document.body) {
            if (el.classList.contains('scroll-lock-ignore')) {
              return true
            }

            el = el.parentElement
          }
        },
      })
    }
    return () => {
      document && enableBodyScroll(document as unknown as Document)
      NavigationPlugin.shared().preventScroll(false)
    }
  }, [navigationData.navigation.navigationOpen])

  useEffect(() => {
    if (appData.app.deviceType === MOBILE) {
      if (state.clickedMenuItem === 'Orders') {
        navigate('/me/orders/')
        setState((prevState) => update(prevState, {
          clickedMenuItem: {
            $set: null,
          },
        }))
      }
      if (state.clickedMenuItem === 'Account') {
        navigate('/me/')
        setState((prevState) => update(prevState, {
          clickedMenuItem: {
            $set: null,
          },
        }))
      }
    }
  }, [customerData?.currentCustomer?.customerType])

  useEffect(() => {
    if (appData?.app?.baseCategoryId) {
      fetchCategories({ variables: { rootCategoryId: appData.app.baseCategoryId } })
    }
  }, [appData?.app?.baseCategoryId])

  let navItem!: NavItem
  // const giftVouchers: NavItem = {
  //   id: v4(),
  //   title: 'Gift Vouchers',
  //   path: '/me/vouchers',
  //   type: NavItemTypeEnum.NAV,
  //   sub: [],
  // }

  return (
    <SidebarContainer
      open={navigationData.navigation.navigationOpen}
      className='scroll-lock-ignore' onClick={_handleBackgroundClose}>
      <Container>
        <Header>
          <Button size='large' variant='logo' icon={LocalIconEnums.LOGO} />
          <Button size='large' variant='nav' icon={LocalIconEnums.CLOSE} onClick={_handleToggleMenu} />
        </Header>
        <AnimatedContainer>
          <If condition={!!state.currentMenu.title}>
            <NavigationBackItem>
              <NavigationBackText onClick={_handleBackClick}>
                <Button size='large' variant='nav' className='back-button' icon={LocalIconEnums.CHEVRON_LEFT} />
                <Title variant='t3' className='back-title'>{state.currentMenu.title}</Title>
              </NavigationBackText>
              <If condition={!!state.currentMenu.path}>
                <Button size='large' variant='text' title='Shop All' className='back-shop' onClick={() => _handleShopAllClicked(state.currentMenu.path)} />
              </If>
            </NavigationBackItem>
          </If>
          <ItemsContainer>
            <For each='navItem' of={state.currentMenu.items}>
              <Choose>
                <When condition={navItem.title === 'Account' || navItem.title === 'Orders'}>
                  <If condition={customerData?.currentCustomer?.customerType === CustomerTypeEnum.GUEST}>
                    <NavigationItem onClick={_handleLoginModal} key={navItem.id}>
                      <Paragraph>{navItem.title}</Paragraph>
                      <If condition={!!navItem.sub.length}>
                        <Button size='large' variant='nav' icon={LocalIconEnums.CHEVRON_RIGHT} />
                      </If>
                    </NavigationItem>
                  </If>
                  <If condition={customerData?.currentCustomer?.customerType === CustomerTypeEnum.REGISTERED}>
                    <NavigationItem onClick={() => _handleItemClicked(navItem)} key={navItem.id}>
                      <Paragraph>{navItem.title}</Paragraph>
                      <If condition={!!navItem.sub.length}>
                        <Button size='large' variant='nav' icon={LocalIconEnums.CHEVRON_RIGHT} />
                      </If>
                    </NavigationItem>
                  </If>
                </When>
                <Otherwise>
                  <NavigationItem onClick={() => _handleItemClicked(navItem)} key={navItem.id}>
                    <Paragraph>{navItem.title}</Paragraph>
                    <If condition={!!navItem.sub.length || navItem.shouldFetchSubCategories}>
                      <Choose>
                        <When condition={state.loadingMenuItem === navItem.id}>
                          <LoaderContainer>
                            <SmallLoader />
                          </LoaderContainer>
                        </When>
                        <Otherwise>
                          <Button size='large' variant='nav' icon={LocalIconEnums.CHEVRON_RIGHT} />
                        </Otherwise>
                      </Choose>
                    </If>
                  </NavigationItem>
                </Otherwise>
              </Choose>
            </For>
          </ItemsContainer>
        </AnimatedContainer>
        <If condition={!!state.incomingMenu}>
          <AnimatedContainer>
            <If condition={!!state.incomingMenu.title}>
              <NavigationBackItem>
                <NavigationBackText onClick={_handleBackClick}>
                  <Button size='large' variant='nav' className='back-button' icon={LocalIconEnums.CHEVRON_LEFT} />
                  <Title variant='t3' className='back-title'>{state.incomingMenu.title}</Title>
                </NavigationBackText>
                <If condition={!!state.incomingMenu.path}>
                  <Button size='large' variant='text' title='Shop All' className='back-shop' onClick={() => _handleShopAllClicked(state.incomingMenu.path)} />
                </If>
              </NavigationBackItem>
            </If>
            <ItemsContainer>
              <For each='navItem' of={state.incomingMenu.items}>
                <Choose>
                  <When condition={navItem.title === 'Account' || navItem.title === 'Orders'}>
                    <If condition={customerData?.currentCustomer?.customerType === CustomerTypeEnum.GUEST}>
                      <NavigationItem onClick={_handleLoginModal} key={navItem.id}>
                        <Paragraph>{navItem.title}</Paragraph>
                        <If condition={!!navItem.sub.length}>
                          <Button size='large' variant='nav' icon={LocalIconEnums.CHEVRON_RIGHT} />
                        </If>
                      </NavigationItem>
                    </If>
                    <If condition={customerData?.currentCustomer?.customerType === CustomerTypeEnum.REGISTERED}>
                      <NavigationItem onClick={() => _handleItemClicked(navItem)} key={navItem.id}>
                        <Paragraph>{navItem.title}</Paragraph>
                        <If condition={!!navItem.sub.length}>
                          <Button size='large' variant='nav' icon={LocalIconEnums.CHEVRON_RIGHT} />
                        </If>
                      </NavigationItem>
                    </If>
                  </When>
                  <Otherwise>
                    <NavigationItem onClick={() => _handleItemClicked(navItem)} key={navItem.id}>
                      <Paragraph>{navItem.title}</Paragraph>
                      <If condition={!!navItem.sub.length || navItem.shouldFetchSubCategories}>
                        <Choose>
                          <When condition={state.loadingMenuItem === navItem.id}>
                            <LoaderContainer>
                              <SmallLoader />
                            </LoaderContainer>
                          </When>
                          <Otherwise>
                            <Button size='large' variant='nav' icon={LocalIconEnums.CHEVRON_RIGHT} />
                          </Otherwise>
                        </Choose>
                      </If>
                    </NavigationItem>
                  </Otherwise>
                </Choose>
              </For>
            </ItemsContainer>
          </AnimatedContainer>
        </If>
        <BottomContainer>
          <Choose>
            <When condition={customerData?.currentCustomer?.customerType === CustomerTypeEnum.REGISTERED}>
              <Paragraph bold className='user-name'>Hi, {customerData.currentCustomer.firstname}</Paragraph>
              <Paragraph bold className='log-out'>
                <Choose>
                  <When condition={state.signOutLoading}>
                    <p>Signing out...</p>
                  </When>
                  <Otherwise>
                    <Button variant='secondary' href='#' onClick={_handleSignOut} title='Sign Out' />
                  </Otherwise>
                </Choose>
              </Paragraph>
            </When>
            <Otherwise>
              <Button variant='nav' size='medium' title='LOGIN' onClick={_handleLoginModal} />
              <Button variant='nav' size='medium' title='SIGNUP' onClick={_handleSignUpModal} />
            </Otherwise>
          </Choose>
        </BottomContainer>
      </Container>
    </SidebarContainer>
  )

}
