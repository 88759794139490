import React from 'react'

import { Button, LocalIconEnums, ButtonVariant } from '@atoms/index'

export enum AddToCartDisplayTypeEnum {
  OUT_OF_STOCK = 'OUT_OF_STOCK',
  SELECT_VARIANT = 'SELECT_VARIANT',
  ADD = 'ADD',
  QUANTITY = 'QUANTITY',
  ICON_ADD = 'ICON_ADD',
}

export interface AddToCartButtonProps {
  variant?: 'details' | 'card'
  className?: string
  displayType: AddToCartDisplayTypeEnum
  currentQuantity: number
  loading: boolean
  fullWidth?: boolean
  disabled?: boolean
  onAdd: () => void
  onIncrease: () => void
  onDecrease: () => void
}

export function AddToCartButton({ variant = 'details', className = '', disabled = false, displayType, currentQuantity, loading, onAdd, onIncrease, onDecrease, fullWidth = true }: AddToCartButtonProps): JSX.Element {

  const title = displayType === AddToCartDisplayTypeEnum.OUT_OF_STOCK
    ? 'NO STOCK'
    : displayType === AddToCartDisplayTypeEnum.QUANTITY
      ? currentQuantity + ''
      : displayType === AddToCartDisplayTypeEnum.SELECT_VARIANT
        ? variant === 'card'
          ? 'VIEW OPTIONS'
          : 'ADD TO'
        : displayType === AddToCartDisplayTypeEnum.ADD
          ? 'ADD TO'
          : ''

  const disabledDisplayType = displayType === AddToCartDisplayTypeEnum.OUT_OF_STOCK || (displayType === AddToCartDisplayTypeEnum.SELECT_VARIANT && variant === 'details')
  const icon = displayType === AddToCartDisplayTypeEnum.ICON_ADD
    ? LocalIconEnums.CART_OUTLINE
    : displayType === AddToCartDisplayTypeEnum.ADD
      ? LocalIconEnums.CART
      : null

  const leftIcon = displayType === AddToCartDisplayTypeEnum.QUANTITY ? LocalIconEnums.MINUS : null
  const rightIcon = displayType === AddToCartDisplayTypeEnum.QUANTITY ? LocalIconEnums.PLUS : null
  const buttonVariant: ButtonVariant = displayType === AddToCartDisplayTypeEnum.QUANTITY ? 'cart' : 'primary'
  const buttonSize = variant === 'details' ? 'large' : 'medium'

  return (
    <Choose>
      <When condition={displayType === AddToCartDisplayTypeEnum.OUT_OF_STOCK}>
        <Button
          className={className}
          variant={buttonVariant}
          title={title}
          fullWidth={fullWidth}
          disabled={true}
          size={buttonSize}
        />
      </When>
      <When condition={displayType === AddToCartDisplayTypeEnum.ADD || displayType === AddToCartDisplayTypeEnum.SELECT_VARIANT}>
        <Button
          className={className}
          fullWidth={fullWidth}
          variant={buttonVariant}
          title={title}
          loading={loading}
          disabled={(disabled) || disabledDisplayType}
          icon={icon}
          leftIcon={leftIcon}
          rightIcon={rightIcon}
          size={buttonSize}
          onLeftIconClick={onDecrease}
          onRightIconClick={onIncrease}
          onClick={onAdd} />
      </When>
      <Otherwise>
        <Button
          className={className}
          variant={buttonVariant}
          title={title}
          fullWidth={fullWidth}
          loading={loading}
          disabled={(disabled) || disabledDisplayType}
          icon={icon}
          leftIcon={leftIcon}
          rightIcon={rightIcon}
          size={buttonSize}
          onLeftIconClick={onDecrease}
          onRightIconClick={onIncrease} />
      </Otherwise>
    </Choose>
  )

}
